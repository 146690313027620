<template>
    <div class="w-[100vw] h-[8vh] bg-background flex items-center justify-center z-10">
        <div class="flex flex-row gap-10 items-center justify-center main z-20">
            <div>
                © 2022 Copyright: Wyn Monde
            </div>

            <div class="about z-30">
                <router-link to="/about"> About Us </router-link>
            </div>

            <div>
                <router-link to="/contact"> Contact Us </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style scoped>
    router-link {
        @apply text-5xl;
    }
</style>