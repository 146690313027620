<template>
    <div class="bg-white w-screen fixed top-0 left-0 py-20 z-[10] h-48">
    </div>
    <div class="lg:bg-white lg:w-screen lg:h-36 flex-col z-10 flex items-center justify-start h-screen fixed top-0 left-0 lg:right-0 lg:py-10">
        <div class ="nav_container h-screen flex lg:flex-row flex-col lg:items-center items-start lg:justify-between lg:w-[70vw] gap-10 lg:gap-0 lg:mt-0 w-[100vw] mt-10">
            <Logo class="lg:ml-0 ml-[2.5rem]" id="logo" :title='title'></Logo>
            <NavBar id="navbar" class="justify-self-end" style=""></NavBar>
        </div>
    </div>
    <svg v-show="mobile" src=" http://www.w3.org/2000/svg" fill="currentColor" class="text-text menu_toggle w-16 h-16 fixed top-12 right-12 z-[11]" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
    </svg>
</template>

<script>
/* eslint-disable */
import NavBar from "./NavBar";
import Logo from "./Logo";
import gsap from "gsap";

var navbar = null; 
var container = null;
var menu_toggle = null;

var menu_open = false;
function toggle_menu() {
    // prevent clicking
    menu_toggle.style.pointerEvents = "none";

    if (menu_open) {
        navbar.style.scale = 1;
        gsap.to(navbar, {opacity: 1, duration: 0.5});
        gsap.to(container, {backgroundColor: "rgba(255, 255, 255, 1)", duration: 0.5, onComplete: () => {
            menu_toggle.style.pointerEvents = "auto";
        }});
        gsap.fromTo(menu_toggle, {rotate: 0}, {rotate: 180, duration: 0.5});
    } else {
        gsap.to(navbar, {opacity: 0, duration: 0.5, onComplete: () => {
            navbar.style.scale = 0;
        }});
        gsap.to(container, {backgroundColor: "rgba(255, 255, 255, 0)", duration: 0.5, onComplete: () => {
            menu_toggle.style.pointerEvents = "auto";
        }});
        gsap.fromTo(menu_toggle, {rotate: 180}, {rotate: 0, duration: 0.5});
    }
}

export default {
    name: 'CustomHeader',
    props: {
        title: {
            type: String,
            default: 'WYN MONDE'
        }
    },
    components: {
        NavBar,
        Logo,
    },
    data() {
        return {
            mobile: null,
        }
    },
    created() {
        this.mobile = window.innerHeight > window.innerWidth;
        window.addEventListener("resize", () => {
            this.mobile = window.innerHeight > window.innerWidth;
            if (this.mobile) {
                menu_open = false;
                toggle_menu();
            }
        });
    },
    mounted() {
        navbar = document.querySelector("#navbar");
        container = document.querySelector(".nav_container");
        menu_toggle = document.querySelector(".menu_toggle");
        if (window.innerHeight > window.innerWidth) {
            navbar.style.opacity = 0;
            navbar.style.scale = 0;
            container.style.backgroundColor = "rgba(255, 255, 255, 0)";
            menu_open = false;
            menu_toggle.addEventListener("click", (e) => {
                menu_open = !menu_open;
                toggle_menu();
            });

            document.addEventListener("click", (e) => {
                if (e.target.closest(".menu_toggle") === null && menu_open && e.target !== menu_toggle) {
                    menu_open = !menu_open;
                    gsap.to(navbar, {opacity: 0, duration: 0.5, onComplete: () => {
                        navbar.style.scale = 0;
                    }});
                    gsap.to(container, {backgroundColor: "rgba(255, 255, 255, 0)", duration: 0.5});
                    gsap.fromTo(menu_toggle, {rotate: 180}, {rotate: 0, duration: 0.5});
                }
            })
        }
    },
    methods: {
        toggle_menu
    }
}


</script>