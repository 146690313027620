<template>
    <div class = "flex flex-col overflow-y-auto overflow-x-hidden pt-40 gap-40 items-center justify-between">
        <div class = "flex flex-col items-center gap-10">
            <h1 class = "text-4xl text-primary"> Tell Us Why You Want To Work With Us! </h1>
            <p class = "w-6/12 text-lg">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium, iure. Voluptates praesentium autem sequi in. Laboriosam nostrum dolorem voluptatibus, modi nemo nihil, obcaecati cum mollitia ut dolore sit aliquam adipisci excepturi eum exercitationem odit eveniet doloremque molestias consequuntur nulla vel animi? Nisi nemo a earum eveniet qui! Vel ratione necessitatibus provident, laborum eius ipsum dolore commodi neque rem, mollitia excepturi est alias hic porro, eligendi aliquid deleniti consequuntur quia dolorem! Maiores eveniet quisquam porro nam voluptatem similique culpa sed obcaecati nesciunt molestias ducimus, sit ut? Quia, repellendus a! Quo distinctio nobis animi tempora placeat natus reprehenderit illo vel voluptatum maxime!
            </p>
        </div>

        <form class = "flex flex-col gap-8">
            <section class = "text-3xl w-full">
                <p>Name: </p>
                <input v-model = "name" type="text" placeholder="Name">
                <br>
            </section>

            <section class = "text-3xl w-full">  
                <p>Email: </p>
                <input v-model = "email" type="text" placeholder="Email">
                <br>
            </section>

            <section class = "text-3xl w-full">
                <p>Alma Mater: </p>
                <input v-model = "almaMater" type="text" placeholder="Alma Mater">
                <br>
            </section>

            <button class = "text-3xl border-3 rounded-full bg-gray-100 p-4">
                Submit
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        
    }
</script>