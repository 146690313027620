import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/Home.vue';
import Do_studios from '../views/Do_studios.vue';
import Do_corporate from '../views/Do_corporate.vue';
import Tech from '../views/Tech.vue';
import Jobs from '../views/Jobs.vue';
import Contact from '../views/Contact.vue';
import About from '../views/About.vue';
import Messages from '../views/Messages.vue';

const routes = [
    {
        path: '/',
        name: 'Wyn Monde',
        component: Home,
    },
    {
        path: '/do-studios',
        name: 'Studios',
        component: Do_studios,
    },
    {
        path: '/do-corporate',
        name: 'Corporate',
        component: Do_corporate,
    },
    {
        path: '/tech',
        name: 'Our Technology',
        component: Tech,
    },
    {
        path: '/jobs',
        name: 'Jobs',
        component: Jobs,
    },
    {
        path: '/contact',
        name: 'Contact Us',
        component: Contact,
    }, 
    {
        path: '/about',
        name: 'About Us',
        component: About,
    },
    {
        path: '/messages',
        name: 'View Messages',
        component: Messages,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return( { top: 0})
    }
});

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});

export default router;