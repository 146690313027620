<template>
    <div class = "flex flex-col overflow-y-hidden overflow-x-hidden pt-40 mt-10 gap-40 items-center ">
        <section class = "flex flex-row justify-between items-center px-40 font-custom gap-64 w-[80vw]">
            <h3 class = "grow-2 text-center text-5xl font-slab text-text">
                Corporate
            </h3>

            <div class = "grow-3 text-center shadow-md text-2xl rounded-3xl p-5 font-custom text-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa ipsa tempora molestiae facilis. Id voluptatem illo necessitatibus aut impedit porro quo blanditiis quasi neque rerum veritatis ipsam nulla non, quam repellat facilis, maxime mollitia corporis ipsum accusantium molestiae praesentium sit voluptatum. Alias distinctio neque repellat debitis ipsum iure consequuntur qui?
            </div>
        </section>

        <section class = "flex flex-row gap-40 justify-center items-center font-custom text-primary relative w-screen">
            <div id = "capture" class = "flex flex-col gap-5 justify-center items-center">
                <ClipboardListIcon class = "w-64 h-64 text-primary text-center p-10 border-2 border-logo rounded-full bg-white shadow-lg"/>
                <h3 class = "text-5xl font-slab w-128 text-center">
                    Develop Requirements
                </h3>
            </div>

            <div id = "analysis" class = "flex flex-col gap-5 items-center">
                <CogIcon class = "w-64 h-64 text-primary text-center p-8 border-2 border-logo rounded-full bg-white shadow-lg" />
                <h3 class = "text-5xl font-slab w-128 text-center">
                    Set Up Technology
                </h3>
            </div>

            <div id = "improvement" class = "flex flex-col gap-5 items-center">
                <DesktopComputerIcon class = "w-64 h-64 text-primary text-center p-8 border-2 border-logo rounded-full bg-white shadow-lg" />
                <h3 class = "text-center text-5xl font-slab w-128">
                    Capture & Analyse Data
                </h3>
            </div>

            <hr class = "absolute bg-logo h-1 w-full left-0 mb-10 -z-10"/>
        </section>

        <section class = "w-[80vw] h-[75vh] mb-2 font-custom">
            <div class = "h-full w-full flex justify-center shadow-md bg-[#f6f5f1] rounded-md flex-nowrap gap-4 overflow-x-auto items-center" style = "min-width: 0; max-height: 100%; min-height: 50%">
                <div class="rounded-lg shadow-lg bg-white max-w-sm w-full">
                    <a href="https://www.theiamarkerless.ca/" target = "_blank" data-mdb-ripple="true" data-mdb-ripple-color="light" class="h-full w-full"> 
                        <img class="rounded-t-lg p-5" src="../assets/theia_logo.png" alt=""/> 
                    </a>
                    <div class="p-6">
                        <h5 class="text-gray-900 text-xl font-medium mb-2">Theia Markerless</h5>
                        <p class="text-gray-700 text-base mb-4">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, necessitatibus.
                        </p>
                        <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">More</button>
                    </div>
                </div>

                <div class="rounded-lg shadow-lg bg-white max-w-sm w-full">
                    <a href="https://contemplas.com/en/motion-analysis/" target = "_blank" data-mdb-ripple="true" data-mdb-ripple-color="light">
                         <img class="rounded-t-lg p-5" src="../assets/contemplas_logo.png" alt=""/>
                    </a>
                    <div class="p-6">
                        <h5 class="text-gray-900 text-xl font-medium mb-2"> Contemplas </h5>
                        <p class="text-gray-700 text-base mb-4">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla, necessitatibus.
                        </p>
                        <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">More</button>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="w-[70vw] h-[50vh] border-4 rounded-xl">

            </div>
        </section>
    </div>
</template>

<script>
    import { ClipboardListIcon } from '@heroicons/vue/outline';
    import { CogIcon } from '@heroicons/vue/outline'
    import { DesktopComputerIcon } from '@heroicons/vue/outline'
    export default {
        components: {
            ClipboardListIcon,
            CogIcon,
            DesktopComputerIcon,
        },
    }
</script>

<style>
    img {
        object-fit: cover;
    }
</style>