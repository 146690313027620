<template>
    <div class = "flex flex-col rounded-xl text-logo justify-center align-middle">
        <router-link to="/">
            <img :src = "require(`../assets/${imageSrc}`)" alt = "Wyn Monde Logo" class = "w-20 h-20 mx-auto">
        </router-link>
        <p class = "my-4 lg:text-xl text-sm text-center font-bold font-slab text-logo"> {{title}} </p>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            title: {
                type: String,
                default: 'WYN MONDE'
            },
            imageSrc: {
                type: String,
                default: 'logo.png'
            }
        }
    }
</script>