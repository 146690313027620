<template>
    <div class = "flex lg:flex-row flex-col lg:justify-center lg:items-center items-start font-slab lg:text-4xl lg:static text-lg lg:gap-0 text-center">
        <div class="menu_item text-text lg:border-r-2 border-primary">
            <router-link to = "/"> HOME </router-link>
        </div>
        <div class="menu_item lg:border-r-2 text-text border-primary relative"  @mouseover="hover = true" @mouseleave="hover=false">
            <router-link to="/do-studios">
                WHAT WE DO
            </router-link>


            <!-- <div v-if="hover" id = "dropdown" class = "flex flex-col text-text w-[103%] bg-[#f5f5f5] text-right gap-1 p-3 divide-y-4">
                <router-link to = "/do-studios"> SOLUTIONS </router-link>
                <router-link to = "/do-corporate"> Corporate </router-link>
            </div> -->
        </div>
        <div class="menu_item lg:border-r-2 text-text border-primary">
            <router-link to = "/tech"> OUR TECHNOLOGY </router-link>
        </div>
        <div class="menu_item lg:border-r-2 text-text border-primary">
            <router-link to = "/about"> ABOUT US </router-link>
        </div>
        <div class="menu_item text-text border-r-0">
            <router-link to = "/contact"> CONTACT US </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    components: {
        // DropDown
    },
    data() {
        return {
            what_we_do: [{
                title: 'Studio',
                link: '/studio'
            },
            {
                title: 'Corporate',
                link: '/corporate'
            }],
            hover: false,
        }
    }
}
</script>

<style>
    .menu_item {
        @apply lg:text-2xl text-3xl font-bold lg:py-3 py-10 relative px-10;
    }

    @media screen and (min-width: 768px) {
        .menu_item::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            background: rgba(246, 245, 241, 1);
            z-index: -1;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.25s ease-in;
        }
    
        .menu_item:hover::after {
            transform: scaleX(1);
            transform-origin: left;
        }
    }
        

    #dropdown {
        position: absolute;
        left: -1%;
        top: 100%;
    }
    div {
        @apply text-text;

    }
    router-link {
        @apply text-text;
    }

</style>