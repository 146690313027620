<template>
    <div class = "flex flex-col items-center mt-56 justify-center overflow-x-hidden">
        <section class = "lg:w-[70vw] w-[80vw] flex flex-col items-center gap-10">
            <div>
                <h3 class = "text-5xl text-primary font-bold text-center font-slab stagger-anim">
                    Our Technology Partners
                </h3>
            </div>

            <div class="flex lg:flex-row flex-col flex-nowrap items-center justify-center rounded-3xl shadow-lg lg:h-[50vh] lg:divide-y-0 lg:divide-x-2 divide-y-2 divide-x-0">
                <div id = "theia" class = "lg:grow-0 lg:shrink-0 lg:w-1/2 lg:h-full h-3/12 w-full flex items-center stagger-anim">
                    <img src="../assets/theia_logo.png" alt="">
                </div>

                <div id = "contemplas" class = "lg:grow-0 lg:shrink-0 lg:w-1/2 lg:h-full h-1/2 w-full flex items-center stagger-anim">
                    <img src="../assets/contemplas_logo.png" alt="">
                </div>
            </div>
        </section>

        <section class = "pt-10 lg:w-[70vw] w-[80vw] my-10">
            <div class = "flex lg:flex-row w-full flex-col items-center justify-center lg:gap-10 gap-20">
                <div class = "lg:w-6/12 w-full">
                    <h3 class = 'text-5xl text-center text-primary font-slab font-bold stagger-anim'>
                        Theia Markerless Software
                    </h3>

                    <p class = 'text-2xl mt-5 font-custom stagger-anim'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci hic voluptas, dolores veniam laborum repellat sed deserunt! Similique beatae error ducimus iure, adipisci reprehenderit, corrupti ea ullam sequi non optio blanditiis? Delectus veniam deleniti consequatur, sunt, officia neque repellat architecto natus perferendis explicabo dolores voluptates tenetur! Expedita tenetur nostrum et.
                    </p>
                </div>

                <div class = "lg:w-6/12 w-full">
                    <h3 class = 'text-5xl text-center text-primary font-slab font-bold stagger-anim'>
                        Contemplas
                    </h3>

                    <p class = 'text-2xl mt-5 font-custom stagger-anim'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae aliquid molestias animi atque sapiente. Quo error officiis animi inventore iste pariatur libero labore possimus, quae amet illo deleniti dolorum sequi illum facilis cum saepe molestiae sapiente hic. Consequatur quidem omnis eum aliquid doloribus magnam inventore mollitia. Corporis quis consequuntur voluptatum?
                    </p>
                </div>
            </div>
        </section>

        <section class = "my-10 lg:w-[70vw] w-[80vw] h-[70vh] rounded-2xl shadow-lg">
            <embed src="https://galaxy.tetsuhiro.dev/" type="" class="w-full h-full rounded-2xl shadow-lg stagger-anim">
        </section>
    </div>
</template>

<script>
    import gsap from 'gsap';
    export default {
        mounted() {
            var list_items = Array.from(document.querySelectorAll(".stagger-anim"));
            list_items.forEach((item, index) => {
                gsap.fromTo(item, {
                    opacity: 0,
                    yPercent: 5
                },{
                    opacity: 1,
                    yPercent: 0,
                    stagger: 0.2,
                    duration: 0.3,
                    delay: 0.2*(index - 2 % 3),
                    scrollTrigger: {
                        trigger: item,
                        start: "top bottom",
                    }
                })
            });
        }
    }
</script>

<style scoped>
    img {
        @apply w-full h-full p-10;
        object-fit: contain;
    }

    p {
        @apply text-center;
    }
</style>