import { initializeApp  } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const firebaseConfig = {

    apiKey: "AIzaSyBkp7KI_xgPxJhTEgt1t0JLbJWdQZ9Uyz4",
  
    authDomain: "wynmonde-firebase.firebaseapp.com",
  
    projectId: "wynmonde-firebase",
  
    storageBucket: "wynmonde-firebase.appspot.com",
  
    messagingSenderId: "876857636302",
  
    appId: "1:876857636302:web:fb1455d3ac0bb3af0ae79f"
  
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };