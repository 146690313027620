<template>
    <div class = "flex flex-col overflow-y-hidden overflow-x-hidden pt-40 mt-10 gap-20 items-center">
        <section class = "flex lg:flex-row flex-col justify-between items-center px-40 font-custom gap-20 w-[70vw]">
            <h3 class = "grow-2 text-center text-5xl font-slab font-bold text-text stagger-anim">
                Solutions
            </h3>

            <div class = "lg:grow-3 lg:w-auto w-[80vw] text-center text-2xl rounded-3xl p-5 font-custom text-text shadow-md stagger-anim">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa ipsa tempora molestiae facilis. Id voluptatem illo necessitatibus aut impedit porro quo blanditiis quasi neque rerum veritatis ipsam nulla non, quam repellat facilis, maxime mollitia corporis ipsum accusantium molestiae praesentium sit voluptatum. Alias distinctio neque repellat debitis ipsum iure consequuntur qui?
            </div>
        </section>

        <section class = "flex lg:flex-row justify-center items-center font-slab text-text relative w-screen lg:mt-32">
            <div v-show=mobile class="absolute h-full border-2 border-dashed border-text stagger-anim">

            </div>
            
            <ol class="lg:border-t-2 lg:border-l-0 lg:w-[70vw] w-[80vw] border-dashed border-text flex items-center justify-around lg:gap-6 lg:flex-row flex-col gap-32">
                <li>
                    <div class="svg_wrapper stagger-anim">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                        </svg>
                    </div>

                    <div class="lg:pt-10 lg:text-5xl text-text font-custom font-bold stagger-anim">
                        Capture
                    </div>
                </li>

                <li>
                    <div class="svg_wrapper stagger-anim">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
                            <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z"/>
                        </svg>
                    </div>
                    <div class="lg:pt-10 lg:text-5xl text-text font-custom font-bold stagger-anim">
                        Analysis
                    </div>
                </li>

                <li>
                    <div class="svg_wrapper stagger-anim">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
                        </svg> 
                    </div>
                    <div class="lg:pt-10 lg:text-5xl text-text font-custom font-bold stagger-anim">
                        Improvement
                    </div>
                </li>
            </ol>
        </section>

        <section class="flex lg:w-[70vw] lg:h-[70vh] w-[80vw] h-[25vh]">
            <div class="w-full h-full gif stagger-anim">
                <div class="w-full h-full overlay_filter rounded-2xl" >

                </div>
            </div>
        </section>

        <section class="">

        </section>

        <!-- <section class = "flex flex-row items-center justify-center w-[70vw]">
            <div id="carouselExampleControls" class="carousel relative w-9/12" data-bs-ride="carousel">
                <div class="carousel-inner relative overflow-hidden">
                    <div class="carousel-item active relative float-left w-full">
                        <img src="../assets/frame_1.jpg" class="block w-full rounded-3xl" alt="Camera Setup"/>
                    </div>

                    <div class="carousel-item relative float-left w-full">
                        <img src="../assets/frame_2.jpg" class="block w-full rounded-3xl" alt="Davy Setup"/>
                    </div>

                    <div class="carousel-item relative float-left w-full">
                        <img src="../assets/frame_3.jpg" class="block w-full rounded-3xl" alt="Program Closeup"/>
                    </div>

                    <div class="carousel-item relative float-left w-full">
                        <img src="../assets/frame_4.jpg" class="block w-full rounded-3xl" alt="Martial Arts Demo"/>
                    </div>

                    <div class="carousel-item relative float-left w-full">
                        <img src="../assets/frame_5.jpg" class="block w-full rounded-3xl" alt="Program Screen"/>
                    </div>

                    <div class="carousel-item relative float-left w-full">
                        <img src="../assets/frame_6.jpg" class="block w-full rounded-3xl" alt="Coaching with program"/>
                    </div>

                    <div class="carousel-item relative float-left w-full">
                        <img src="../assets/frame_7.jpg" class="block w-full rounded-3xl" alt="Ballerina"/>
                    </div>
                </div>

                <button class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" >
                    <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>

                <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                </div>
            </div>
        </section> -->

        <!-- <section class = "w-[80vw] h-[50vh] mb-2 font-custom">
            <div id="carouselExampleCaptions" class="carousel slide relative carousel-dark h-full w-full" data-bs-ride="carousel">
                <div class="carousel-inner relative w-full overflow-hidden h-full pt-20">
                    <div class="carousel-item active relative float-left w-full text-center h-full">
                        <p class="text-3xl italic mx-auto text-gray-700 max-w-4xl w-full font-custom">
                            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet
                            numquam iure provident voluptate esse quasi, voluptas nostrum quisquam!"
                        </p>
                        <div class="mt-12 mb-6 flex justify-center">
                            <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp"
                            class="rounded-full w-24 h-24 shadow-lg"
                            alt="smaple image"
                            />
                        </div>
                        <p class="text-gray-500 text-xl font-slab">- Anna Morian</p>

                        <ul class="flex justify-center mb-0"> -->

                            <!-- full star -->
                            <!-- <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li> -->

                            <!-- half star -->
                            <!-- <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star-half-alt" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536 512">
                                    <path fill="currentColor" d="M508.55 171.51L362.18 150.2 296.77 17.81C290.89 5.98 279.42 0 267.95 0c-11.4 0-22.79 5.9-28.69 17.81l-65.43 132.38-146.38 21.29c-26.25 3.8-36.77 36.09-17.74 54.59l105.89 103-25.06 145.48C86.98 495.33 103.57 512 122.15 512c4.93 0 10-1.17 14.87-3.75l130.95-68.68 130.94 68.7c4.86 2.55 9.92 3.71 14.83 3.71 18.6 0 35.22-16.61 31.66-37.4l-25.03-145.49 105.91-102.98c19.04-18.5 8.52-50.8-17.73-54.6zm-121.74 123.2l-18.12 17.62 4.28 24.88 19.52 113.45-102.13-53.59-22.38-11.74.03-317.19 51.03 103.29 11.18 22.63 25.01 3.64 114.23 16.63-82.65 80.38z"></path>
                                </svg>
                            </li>
                        </ul>
                    </div>

                    <div class="carousel-item relative float-left w-full text-center h-full">
                        <p class="text-3xl italic mx-auto text-gray-700 max-w-4xl">
                            "Totally not biased review!"
                        </p>
                        <div class="mt-12 mb-6 flex justify-center">
                            <img src="../assets/davy_image.jpg" class="rounded-full w-24 h-24 shadow-lg" style = "object-fit: cover"/>
                        </div>
                        <p class="text-gray-500 text-2xl font-slab">- Davy Lim</p>

                        <ul class="flex justify-center mb-0">

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>

                            <li>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                                </svg>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <button class="carousel-control-prev absolute top-[25%] flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next absolute top-[25%] flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </section> -->
    </div>
</template>

<script>
    import gsap from 'gsap';
    export default {
        data() {
            return {
                mobile: null,
            }
        },
        mounted() {
            this.mobile = window.innerHeight > window.innerWidth;
            var list_items = Array.from(document.querySelectorAll(".stagger-anim"));
            list_items.forEach((item, index) => {
                gsap.fromTo(item, {
                    opacity: 0,
                    yPercent: 5
                },{
                    opacity: 1,
                    yPercent: 0,
                    stagger: 0.2,
                    duration: 0.3,
                    delay: 0.2*(index - 2 % 3),
                    scrollTrigger: {
                        trigger: item,
                        start: "top bottom",
                    }
                })
            });      
        }
    }
</script>

<style scoped>
    img {
        object-fit: cover;
        @apply rounded-2xl;
    }

    .gif {
        @apply w-full h-full rounded-2xl;
        background: url("../assets/smaller_test.gif") no-repeat;
        background-size: cover;
    }

    .overlay_filter {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(1px);
    }

    svg {
        @apply lg:p-16 text-primary lg:h-64 lg:w-64 h-32 w-32 p-8;
    }

    .svg_wrapper {
        @apply rounded-full border-2 border-text lg:-mt-32 bg-white;
    }

    li {
        @apply flex lg:flex-col flex-row lg:justify-center items-center justify-around lg:gap-0 lg:w-auto w-[70vw] ;
    }

    li > .font-custom {
        @apply lg:w-auto w-[10rem] lg:text-5xl text-2xl flex flex-col items-center justify-center;
    }
</style>


