<template>
    <CustomHeader title='WYN MONDE'>
    </CustomHeader>
    <!-- eslint-disable -->
    <router-view></router-view>
    <Footer></Footer>
</template>

<script>

import CustomHeader from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: 'App',
  components: {
    CustomHeader,
    Footer
  }
}
</script>

<style>
    body {
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.1);
    }

    ::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 100%, 0.2);
    }

    @supports (scrollbar-color: red blue) {
        * {
            scrollbar-color: hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1);
            scrollbar-width: thin;
        }
    }
</style>
