<template>
    <div class="flex flex-col items-center justify-center my-56 font-custom lg:gap-2 gap-10 rounded-2xl">
        <div class="lg:w-[30vw] w-[80vw] ">
            <div class="bg-white block rounded-2xl shadow-lg">
                <div class="relative overflow-hidden bg-no-repeat bg-cover stagger-anim">
                    <img src="../assets/new_pic.png" class="w-[80vw] lg:px-20 lg:py-20 px-10 py-10 rounded-full stagger-anim" />
                    <a href="https://www.linkedin.com/in/davy-lim-401ba2a5/"> <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"></div> </a>
                    <!-- <svg class="absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style="left: 0; bottom: 0"> <path fill="#fff" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"> </path> </svg> -->
                </div>

                <div class="p-6 font-custom text-center -mt-[2rem] stagger-anim">
                    <h5 class="text-5xl font-bold font-slab mb-4 text-primary stagger-anim">Davy Lim</h5>
                    <p class="text-text mb-4 text-2xl stagger-anim">Founder of Wyn Monde</p>
                </div>
            </div>
        </div>

         <div class = "lg:w-[70vw] w-[80vw] font-custom text-xl text-text text-center mt-10 bg-background lg:p-20 gap-20 p-5 rounded-2xl flex-col flex items-center justify-center">
            <div class="lg:w-7/12 w-11/12 stagger-anim">
                <p>
                    Trained as a design engineer at Nanyang Technological University, Singapore, 
                    Davy has worked extensively in the field of Biomechanics and Human Factors Engineering, applied across diverse sectors such as sports, education and defence.
                </p>
                <br>
                <p>
                    For nearly a decade, Davy was the Lead Biomechanist for several of Singapore's National Sports Teams, including swimming, shooting and bowling. 
                    He played an instrumental role in helping athletes improve their performance through movement optimisation, performance analysis and equipment configuration, 
                    resulting in medal-winning performances by Singapore's athletes in major competitions such as the Olympics, Paralympics, Asian Games and SEA Games from 2004 till 2015.
                </p>
                <br>
                <p>
                    Davy was also involved in the design and equipping of the Singapore Sports Institute's Biomechanics laboratory and aquatics centre. 
                    His lifelong passion for athletic performance has seen him take up pivotal positions in this industry throughout his two-decade-long career.
                </p>
                <br>
                <p>
                    He holds a Master's Degree in Smart Product Design and has published in numerous research papers on novel sports biomechanics methodology for specific sports and more, since 2004.
                </p>
                <br>
                <p>
                    Beyond his professional career, Davy has also served as Secretary for the Human Factors and Ergonomics Society of Singapore.
                </p>
            </div>
            <ul class="list-inside flex mx-auto justify-center stagger-anim">
                <a href="https://www.linkedin.com/in/davy-lim-401ba2a5/" class="px-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-8 h-8 text-primary"> <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /> </svg>
                </a>
            </ul>
        </div>

    </div>
</template>

<script>
    import gsap from 'gsap';
    export default {
        mounted() {
            var list_items = Array.from(document.querySelectorAll(".stagger-anim"));
            list_items.forEach((item, index) => {
                gsap.fromTo(item, {
                    opacity: 0,
                    yPercent: 5
                },{
                    opacity: 1,
                    yPercent: 0,
                    stagger: 0.2,
                    duration: 0.3,
                    delay: 0.2*(index - 2 % 3),
                    scrollTrigger: {
                        trigger: item,
                        start: "top bottom",
                    }
                })
            });
        }
    }
</script>

<style scoped>
    img {
        object-fit: cover;
    }
</style>