<template>
    <div class = "mt-64 bg-gray-100">
        <div :key = "message.id" v-for="message in messages">
            <div>
                {{message.name}}
            </div>
            <div>
                {{message.message}}
            </div>
        </div>
    </div>
</template>

<script>

    import { db } from '../firebase/db';
    import { collection, getDocs, onSnapshot } from 'firebase/firestore';
    import { ref, onMounted } from 'vue';

    export default {
        data() {
            return db;
        },
        setup() {
            const messages = ref([]);

            onMounted(() => {
                onSnapshot(collection(db, 'contact'), snapshot => {
                    var fb_messages = []
                    snapshot.forEach(doc => {
                        const message = {
                            id: doc.id,
                            name: doc.data().Name,
                            email: doc.data().Email,
                            message: doc.data().Message
                        }

                        fb_messages.push(message);
                    });
                    messages.value = fb_messages;
                })
            });
            return {
                messages
            };
        }
    }
</script>